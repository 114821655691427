import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axios from 'axios';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import * as uuid from 'uuid';

import { isValidForm, isValidMail, handleMixedFormChange } from 'components/Headers/DiscussionProposal/formHelper';
import useStyles from 'components/Headers/HeaderButton/buttonStyles';
import Modal from 'components/Modal';
import PrivacyAgree from 'components/Privacy';
import { MixedForm, BooleanForm } from 'models/typeAliases';


export const ANONYMOUS_USER_COOKIE_NAME = 'sc_anonymous_user';
export const ANONYMOUS_USER_COOKIE_FIELD = 'id';

type Props = {
  close: () => void;
  visible: boolean;
}

const DiscussionProposal = (props: Props): JSX.Element => {

  const classes = useStyles();
  const [cookies, setCookie] = useCookies([ANONYMOUS_USER_COOKIE_NAME]);
  const [buttonTxt, setButtonTxt] = useState('WYŚLIJ PROPOZYCJĘ');
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<MixedForm>({ title: '', thesis: '', mail: '', rodo: false });
  const [err, setErr] = useState<BooleanForm>({ title: false, thesis: false, mail: false, rodo: false });

  /* Set the cookie for the PV3 API request */
  const cookieValue = cookies ? cookies[ANONYMOUS_USER_COOKIE_FIELD] : undefined;
  const anonymousUserId = cookieValue || uuid.v4();
  if (!cookies || !cookieValue) setCookie(ANONYMOUS_USER_COOKIE_FIELD, anonymousUserId);

  const handleSubmit = (): void => {
    if (!isValidForm(form, err, setErr) || !isValidMail(form.mail as string, err, setErr)) return;
    else {
      setLoading(true);
      axios.post(`${process.env.SWARMCHECK_PV3_API_URL}/discussions/proposals/`, {
        'anonymousUserId': anonymousUserId,
        'subscriber': form.mail,
        'title': form.title, 
        'mainThesis': form.thesis,
        'projectUrl': 'mysl-jak-naukowiec'
      })
        .then(() => {
          setLoading(false);
          setButtonTxt('DZIĘKUJEMY!');
          setTimeout(() => props.close(), 1000);
        })
        .catch(() => {
          setLoading(false);
          setButtonTxt('SPRÓBUJ PONOWNIE');
        });
    }
  };

  const handleClose = (): void => {
    setForm({ title: '', thesis: '', mail: '', rodo: false });
    setErr({ title: false, thesis: false, mail: false, rodo: false });
    setButtonTxt('WYŚLIJ PROPOZYCJĘ');
    props.close();
  };

  return (
    <Modal show={props.visible} modalClosed={props.close}>
      <div className={`proposal${props.visible ? `` : `--hidden`}`}>
        <div className="proposal__content">
          <h1>Zaproponuj temat dyskusji</h1>
          <p>
            Chcemy tworzyć przestrzeń do dialogu pomiędzy różnymi poglądami dotyczącymi nauki. 
            Jeśli masz pomysł na temat do wspólnej pracy, prześlij go nam!
          </p>
          <div className="proposal__input">
            <TextField
              label="Wpisz propozycję tematu dyskusji"
              error={err.title}
              value={form.title}
              onChange={handleMixedFormChange(form, setForm, 'title')}
              fullWidth
            />
          </div>
          <div className="proposal__input">
            <TextField 
              label="Wpisz propozycję tezy głównej"
              error={err.thesis}
              value={form.thesis}
              onChange={handleMixedFormChange(form, setForm, 'thesis')}
              fullWidth
            />
          </div>
          
          <div className="proposal__input">
            <TextField 
              label="Podaj swój email"
              error={err.mail}
              helperText={err.mail ? 'Proszę podać poprawny adres e-mail' : ''}
              value={form.mail}
              onChange={handleMixedFormChange(form, setForm, 'mail')}
              fullWidth
            />
          </div>
          <div className="proposal__input">
            <PrivacyAgree 
              rodo={form.rodo as boolean} 
              handleRodoChange={handleMixedFormChange(form, setForm, 'rodo')} 
              error={err.rodo} 
            />
          </div>
          <Button onClick={(): void => handleSubmit()} className={`${classes.secondary}`}>
            {loading ? <CircularProgress disableShrink size={30} /> : buttonTxt}
          </Button>
        </div>
        <div className="proposal__close">
          <Close onClick={(): void => handleClose()} />
        </div>
      </div>
    </Modal>
  );
};

export default DiscussionProposal;