import { Button } from '@material-ui/core';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import useStyles from 'components/Headers/HeaderButton/buttonStyles';

const MenuMobile = (): JSX.Element => {

  const classes = useStyles();
  const data = useStaticQuery(graphql`
  query {
    contentfulAsset(title: {eq: "MJN_logo_white"}) {
      fixed(width: 220, height: 78) {
        ...GatsbyContentfulFixed_noBase64
      }
    }
  }
  `);

  return (
    <div className={`menu-mobile`}>
      <Link className="menu-mobile__item" to="/"><Img fixed={data.contentfulAsset.fixed} /></Link>
      <Link className="menu-mobile__item" to="/">Home</Link>
      <Link className="menu-mobile__item" to="/materiały">Materiały</Link>
      <Link className="menu-mobile__item" to="/projekt">O projekcie</Link>
      <Link className="menu-mobile__item" to="/kontakt">Kontakt</Link>
      <Link className="phone__link" to="/dyskusje">
        <Button className={`phone__button ${classes.main}`}>
          ARGUMENTUJ Z NAMI
        </Button>
      </Link>
    </div>
  );
};

export default MenuMobile;