import React from 'react';

type Props = {
  show: boolean; 
  clicked: () => void;
}

const Backdrop = (props: Props): JSX.Element | null => (
  props.show 
    ? <div className="backdrop backdrop--regular" onClick={props.clicked}></div> 
    : null
);

export default Backdrop;