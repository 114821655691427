import { useScrollTrigger } from '@material-ui/core';
import { Menu, Close } from '@material-ui/icons';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';

import HeaderButton from '../HeaderButton';

import MenuMobile from './MenuMobile';

type Props = {
  discussion?: boolean;
}

const PhoneHeader = (props: Props): JSX.Element => {

  const [menu, setMenu] = useState(false);
  const trigger = useScrollTrigger();

  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "MJN_bulb"}) {
        fixed(width: 44, height: 58) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
    }
  `);

  const headerStyle = (!trigger && !menu) ? `--visible` : `--hidden`;

  return (
    <>
      <header className={`phone phone${headerStyle}`}>
        <Link to="/" className="phone__logo"> 
          <Img fixed={data.contentfulAsset.fixed} />
        </Link>
        <div className="phone__list">
          <Link className="phone__list__item" to="/dyskusje">
            Dyskusje             
          </Link>
          <Link className="phone__list__item" to="/materiały">
            Materiały             
          </Link>
          <Link className="phone__list__item" to="/projekt">
            O projekcie            
          </Link>
        </div>
        <HeaderButton mode="phone" discussion={props.discussion} />
        <button className="phone__icon" onClick={(): void => setMenu(true)}>
          <Menu fontSize="inherit" />
        </button>
      </header>
      <div className={`menu-mobile${menu ? `` : `--off`}`}>
        <div onClick={(): void => setMenu(false)}>
          <MenuMobile />
        </div>
        <button className="menu-mobile__close" onClick={(): void => setMenu(false)}>
          <Close fontSize="inherit" />
        </button>
      </div>
    </>
  );
};

export default PhoneHeader;