import { Checkbox } from '@material-ui/core';
import React from 'react';

type Props = {
  rodo: boolean;
  error: boolean;
  handleRodoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PrivacyAgree = (props: Props): JSX.Element => {

  return (
    <div className="privacy">
      <Checkbox 
        color="default"
        checked={props.rodo} 
        onChange={props.handleRodoChange}
      />
      <p className={`privacy__text privacy__text${props.error ? `--err` : ``}`}>
        {`Wyrażam zgodę na przetwarzanie moich powyższych danych osobowych przez Fundację
          Optimum Pareto z siedzibą w Krakowie w celu przesyłania mi powiadomień oraz 
          informuję, że zapoznałem się z `} 
        <a 
          href="https://cutt.ly/ReXflS7" 
          rel="noopener noreferrer" 
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
            Polityką Prywatności
        </a> 
        {` i ją akceptuje.`}
      </p>
    </div>
  );
};

export default PrivacyAgree;