import { makeStyles } from '@material-ui/core/styles';

import styles from 'styles/base/_variables.scss';

const useStyles = makeStyles((theme) => ({
  main: {   
    background: styles.pinkButton,
    boxShadow: `0px 9px 17px ${styles.pinkShadow}`,
    borderRadius: '15px',
    fontFamily: styles.korolevFont,
    fontSize: '13px',
    fontWeight: 300,
    letterSpacing: '1.25px',
    color: styles.whiteText,
    [theme.breakpoints.up('md')]: {
      borderRadius: '10px',
      fontSize: '17px',
    },
    '&:hover': {
      background: styles.pinkButton,
    }
  },
  secondary: {   
    background: styles.yellowButton,
    boxShadow: `0px 9px 17px ${styles.yellowShadow}`,
    borderRadius: '15px',
    fontFamily: styles.korolevFont,
    fontSize: '13px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    color: styles.whiteText,
    [theme.breakpoints.up('md')]: {
      borderRadius: '10px',
      fontSize: '17px',
    },
    '&:hover': {
      background: styles.yellowButton,
    }
  },
}));

export default useStyles;