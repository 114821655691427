import { Facebook, Instagram } from '@material-ui/icons';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import HeaderButton from '../HeaderButton';

type Props = {
  discussion?: boolean;
}

const Header = (props: Props): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      logo: contentfulAsset(title: {eq: "MJN_logo"}) {
        fixed(width: 228, height: 80) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
    }
  `);

  return (
    <header className="sticky">
      <Link to="/" className="sticky__logo"> 
        <Img fixed={data.logo.fixed} />
      </Link>
      <div className="sticky__list">
        <Link className="sticky__list__item" to="/">Home</Link>
        <Link className="sticky__list__item" to="/dyskusje">Dyskusje</Link>
        <Link className="sticky__list__item" to="/materiały">Materiały</Link>
        <Link className="sticky__list__item" to="/projekt">O projekcie</Link>
        <Link className="sticky__list__item" to="/kontakt">Kontakt</Link>
      </div>
      <HeaderButton mode="sticky" discussion={props.discussion} />
      <div className="sticky__icons">
        <a 
          href="https://www.facebook.com/mysljaknaukowiec/" 
          rel="noopener noreferrer" 
          target="_blank"
          className="sticky__icon"
        >
          <Facebook fontSize="inherit" />
        </a>
        <a 
          href="https://www.instagram.com/mysl_jak_naukowiec" 
          rel="noopener noreferrer" 
          target="_blank"
          className="sticky__icon"
        >
          <Instagram fontSize="inherit" />
        </a>
      </div>
    </header>
  );
};

export default Header;