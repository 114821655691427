import { Button } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useState } from 'react';

import DiscussionProposal from '../DiscussionProposal';

import useStyles from './buttonStyles';

type Props = {
  discussion?: boolean;
  mode: 'sticky' | 'phone';
}

const HeaderButton = (props: Props): JSX.Element => {

  const [proposalPanelVisible, setProposalPanelVisible] = useState(false);
  const classes = useStyles();

  const button = props.discussion
    ? <div className={`${props.mode}__link`}>
      <Button 
        onClick={(): void => setProposalPanelVisible(true)}
        className={`${props.mode}__button ${classes.secondary}`} 
      >
        ZAPROPONUJ DYSKUSJĘ
      </Button>
    </div>
    : <Link className={`${props.mode}__link`} to="/dyskusje">
      <Button className={`${props.mode}__button ${classes.main}`} >ARGUMENTUJ Z NAMI</Button>
    </Link>;
  
  return (
    <>
      {button}
      <DiscussionProposal 
        visible={proposalPanelVisible} 
        close={(): void => setProposalPanelVisible(false)} 
      />
    </>
  );
};

export default HeaderButton;