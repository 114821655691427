import React from 'react';

import Backdrop from './Backdrop';

type Props = {
  show: boolean;
  modalClosed?: () => void;
  children: Element | JSX.Element;
}

const Modal = (props: Props): JSX.Element => {
  let open = false;
  open = props.show ? true : false;

  return (
    <>
      <Backdrop show={props.show} clicked={(): void => {
        if (props.modalClosed) props.modalClosed(); 
      }}
      />
      <div className={open === true ? 'modal modal--visible' : 'modal modal--hidden'}>
        {props.children}
      </div>
    </>
  );
};

export default React.memo(
  Modal,
  (prevProps, nextProps) =>
    nextProps.show === prevProps.show &&
    nextProps.children === prevProps.children
);