import { SetMixedForm, MixedForm, InputChange, BooleanForm, SetBooleanForm, StringForm, SetStringForm } from 'models/typeAliases';

export const isValidMail = (email: string, error: BooleanForm, setError: SetBooleanForm): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(email).toLowerCase());
  if (!isValid) {
    setError({ ...error, mail: true });
    return false;
  }
  else return true;
};

export const isValidForm = (form: MixedForm, error: BooleanForm, setError: SetBooleanForm): boolean => {
  const haveErrors = Object.entries(form).map(([key, value]) => {
    if (!value && key in error) {
      setError((prevState) => ({ ...prevState, [key]: true }));
      return true;
    }
    return false;
  }).find(err => err === true);
  return !haveErrors;
};

export const handleFormChange = (
  form: StringForm, setForm: SetStringForm, prop: string
): (event: InputChange) => void => {
  return (event: InputChange): void => {
    setForm({ ...form, [prop]: event.target.value });
  };
};

export const handleMixedFormChange = (
  form: MixedForm, setForm: SetMixedForm, prop: string
): (event: InputChange) => void => {
  return (event: InputChange): void => {
    if (event.target.type === 'checkbox') setForm({ ...form, [prop]: event.target.checked });
    else setForm({ ...form, [prop]: event.target.value });
  };
};